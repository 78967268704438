.item img{
    width: 600px;
    height: auto;
}

.item button{
    width: 200px;
    height: 50px;
    border-radius: 20px;
    border: none;
    background-color: #3256a8;
    color: white;
    font-weight: bold;
    cursor: pointer;
}

.item-header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.item-description{
    padding: 0px 400px;
}

.subproduct{
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.subproduct-detail{
    text-align: center;
}

.subproduct-title p{
    color: #3256a8;
    cursor: pointer;
}

@media (max-width: 800px) {
    .item img{
        width: 400px;
    }
    .item-header{
        flex-direction: column;
        gap: 1px;
        flex-wrap: wrap;
        word-wrap: break-word;
    }
    .item-header h1{
        font-size: 2rem;
        text-align: center;
        word-wrap: break-word;
    }
    .item-header p{
        font-size: 1.5rem;
        text-align: center;
    }
    .item button{
        width: 300px;
        height: 100px;
        font-size: 2rem;
    }
    .item-description{
        padding: 0px 50px;
        font-size: 1.2rem;
    }
    .subproduct{
        flex-direction: column;
    }
}