.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #f8f8f8;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .navbar img{
    width: 20rem;
    height: auto;
    cursor: pointer;
  }
  
  .menu-toggle {
    display: none;
  }
  
  .navbar-items {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    gap: 30px;
  }

  .navbar-webshop a{
    text-decoration: none;
    color: #d12815;
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    text-transform: uppercase;
    border: 3px solid #3256a8 ;
    padding: 10px;
    border-radius: 10px;
  }
  
  .navbar-items a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    transition: color 250ms ease-in-out, transform 250ms ease-in-out;
    will-change: color, transform;
}

.navbar-items a:hover {
    color: #3256a8;
    transform: scale(1.1);
}

.nav-info{
  transition: color 250ms ease-in-out, transform 250ms ease-in-out;
}

.nav-info:hover {
    color: #3256a8;
    transform: scale(1.1);
}

  .navbar-items .facebook{
    font-size: 30px;
    color: #3256a8;
    cursor: pointer;
  }
  
  .nav-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .nav-info a{
    text-decoration: none;
    color: black;
  }
  
  .navbar-items-small {
    display: none;
    flex-direction: column;
    list-style: none;
    gap: 30px;
  }
  
  .navbar-items-small a{
    text-decoration: none;
    color: black;
  }
  
  .navbar-items-small li{
    width: 100%;
    text-align: center;
  }
  
  .navbar-items-small .nav-info{
    display: flex;
    flex-direction: column;
  }
  
  .navbar-items-small.visible {
    display: flex;
  }
  
  .navbar-items-small.hidden {
    display: none;
  }
  
  @media (max-width: 800px) {
    .navbar{
      flex-direction: column;
      gap: 30px;
    }
  
    .navbar-items-small{
      align-items: center;
    
    }
  
    .menu-toggle {
      display: block;
    }
  
    .navbar-items {
      display: none;
    }
  
    .navbar button{
      font-size: 2rem;
    }
  }