.services_c{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
  
  .service-cards{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  
  .service-card{
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1px;
    border: 7px solid #3256a8;
    padding: 20px;
    text-align: center;
    box-shadow: 5px 5px 10px black;
    transition: scale 500ms, color 500ms, background-color 500ms, border 500ms;
  }

  .service-card:hover{
    background-color: #3256a8;
    border: 7px solid white;
    color: white;
    transform: scale(1.1);
  }

  .service-card:hover h1{
    color: white;
  }

  .service-card:hover svg{
    color: white;
  }
  
  .service-card h1{
    font-size: 2rem;
    color: #3256a8;
  }
  
  .service-card svg{
    font-size: 5rem;
    color: #3256a8;
  }
  
  @media (max-width: 800px) {
  
    .service-cards{
      flex-direction: column;
    }
  }