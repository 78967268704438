.carousel img{
    width: 200px;
    height: auto;
  }
  
  .carousel{
    margin-top: 200px;
    margin-left: 100px;
    margin-right: 100px;
  }
  
  @media (max-width: 800px) {
    .carousel{
      margin: 0;
      margin-top: 50px;
    }
  
    .carousel img{
      width: 100px;
    }
  }