.impressum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.impressum h1{
    font-size: 3rem;
    color: #3256a8;
}

.impressum-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.impressum-item h2{
    color: #3256a8;
}

.impressum-item ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}