.about{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.about h1{
    font-size: 3rem;
    line-height: 2px;
}

.about-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;
}

.about-card{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    padding-right: 50px;
    height: 300px;
    box-shadow: 1px 1px 10px #3256a8;
    transition: scale 500ms, background-color 500ms, color 500ms;
}

.about-card:hover{
    scale: 1.1;
}

.about-card:hover .card-title{
    background-color: white;
    color: #3256a8;
}

.about-card:hover .card-desc{
    background-color: #3256a8;
    color: white;
}

.about-card .card-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 300px;
    text-align: center;
    color: white;
    background-color: #3256a8;
    border-radius: 15px;
}

.about-card .card-desc{
    width: 600px;
    background-color: white;
    color: #3256a8;
    border-radius: 15px;
    padding: 15px;
}

@media (max-width: 800px){
    .about-card{
        flex-direction: column;
        height: auto;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
    .about-card .card-title{
        width: 300px;
    }
    .about-card .card-desc{
        width: 300px;
    }
    .about-card:hover{
        scale: 1;
    }
    .about-card:hover .card-title{
        background-color: #3256a8;
        color: white;
    }
    .about-card:hover .card-desc{
        background-color: white;
        color: #3256a8;
    }
}