.webshop{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    position: fixed;
    top: 30%;
    right: 35%;
}

.webshop img {
    width: 600px;
    height: auto;
}

.webshop h1{
    font-size: 1.5rem;
    font-weight: bold;
}

@media (max-width: 800px) {
    .webshop{
        top: 40%;
        right: 15%;
    }

    .webshop img{
        width: 300px;
    }
}