.hero{
    position: relative;
  }
  
  .hero img{
    width: 100%;
    height: 700px;
  }
  
  .hero-title{
    position: absolute;
    left: 10%;
    top: 30%;
    background-color: white;
    border-radius: 20px;
    padding: 30px;
    text-align: center;
    font-size: 1.25rem;
    word-spacing: 10px;
    line-height: 4rem;
    box-shadow: 10px 10px 5px black;
  }
  
  .hero-title button{
    height: 60px;
    width: 300px;
    border-radius: 20px;
    border: none;
    background-color: black;
    color: white;
    cursor: pointer;
    transition: scale 1s;
  }
  
  .hero-title button:hover{
    scale: 1.2;
  }
  
  @media (max-width: 800px) {
    .hero img{
      height: 500px;
      object-fit: fill;
    }
  
    .hero-title{
      font-size: 0.8rem;
      word-spacing: 3px;
      top: 20%;
    }
  }