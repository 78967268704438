.products_c{
    margin-top: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .cards{
    display: flex;
    justify-content: center;
    gap: 80px;
  }
  
.card {
    position: relative;
    width: 400px;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 10px solid #3256a8;
    padding: 5px;
    background-color: white;
    color: black;
    box-shadow: 5px 5px 10px black;
    transition: transform 500ms, background-color 500ms, color 500ms, border 500ms;
}

.card:hover {
    border-color: white;
    background-color: #3256a8;
    color: white;
    transform: scale(1.05);
}
  
  .card img{
    width: 350px;
    height: auto;
    border-radius: 25px;
  }
  
  .details{
    text-align: center;
  }
  
  .details button{
    position: absolute;
    bottom: 20px;
    right: 30%;
    width: 200px;
    height: 50px;
    font-size: 1.5rem;
    border: none;
    border-radius: 10px;
    color: #3256a8;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    transition: scale 100ms;
  }
  
  .details button:hover{
    scale: 1.2;
    cursor: pointer;
  }
  
  @media (max-width: 800px) {
  
    .products_c{
      margin-top: 10px;
    }
  
    .cards{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .card{
      width: 80%;
      height: 400px;
    }
  
    .card img{
      width: 50%;
    }
  
    .details button{
      right: 20%;
    }
  }