.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    justify-content: center;
    background-color: #3256a8;
    color: white;
    text-align: center;
    height: fit-content;
    margin-top: 200px;
  }

  .footer-info{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
  }

  .footer-info h2{
    line-height: 100px;
  }

  .footer-info-files a{
    text-decoration: none;
    color: white;
  }

  @media (max-width: 800px) {
    .footer{
      font-size: 0.7rem;
    }
  }