
.main{
  display: flex;
  flex-direction: column;
  gap: 0px;
  background-color: #ededeb;
}

.counter{
  width: 100%;
  height: 150px;
  background-color: #3256a8;
  margin-top: 100px;
  font-size: 1.5rem;
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.counter-number{
  font-size: 4rem;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.thank-you-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 700px;
}

.thank-you-page-back button{
  width: 300px;
  height: 70px;
  border: none;
  border-radius: 20px;
  background-color: #3256a8;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}

.thank-you-page-card{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 30px;
  background-color: #3256a8;
  width: 600px;
  border-radius: 30px;
  color: white;
  margin-top: 100px;
}











