.services-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin-top: 30px;
}

.services-main .title{
    margin-top: 0;
    margin-bottom: 70px;
    text-transform: uppercase;
    font-size: 3rem;
}

.services{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: fit-content;
    background-color: #3256a8;
    max-width: fit-content;
    padding: 50px;
    border-radius: 20px;
    color: white;
}

.services-cards{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;
}

.services-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    background-color: white;
    color: #3256a8;
    border-radius: 10px;
    border: 4px solid #3256a8;
    box-shadow: 2px 2px 10px black;
    padding: 40px;
    height: 500px;
}

.services-card h2{
    padding-top: 10px;
    margin-top: 0;
}

.services-card ul{
    list-style: none;
    line-height: 30px;
    padding: 0;
}

.services-main h1{
    text-align: center;
}
.service-info-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; 
    justify-items: center;
    align-items: start; 
  }
  
  .service-info-card {
    background-color: #3256a8;
    color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 2px 2px 10px black;
    display: flex; 
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    transition: background-color 500ms, color 500ms, border 500ms;
  }

  .service-info-card:hover{
    background-color: white;
    color: #3256a8;
  }

  .service-info-card:hover a{
    color: #3256a8;
  }

  .service-info-card-title h1 {
    font-size: 1.2rem;
  }

  .service-info-card a{
    text-decoration: none;
    color: white;
  }
  
  .service-info-card .service-info-card-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .service-info-card .service-infos-card-phone {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

@media (max-width: 800px) {
    .services-cards{
        flex-direction: column;
        gap: 10px;
    }

    .service-info-cards{
        grid-template-columns: repeat(1, 1fr);
    }
}