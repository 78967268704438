.products{
    background-color: #e8eded;
}

.category-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.category-toggle {
    display: none;
    width: 100%;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
  }

.products-main{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.products-category img{
    width: 200px;
    height: auto;
}

.products-category{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    width: fit-content;
    padding:10px;
    margin-left: 30px;
    margin-top: 20px;
    color: white;
}

.products-category input{
    height: 50px;
    border-radius: 10px;
    width: 300px;
}

.category-name{
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 10px;  
}

.category-name h2{
    font-size: 20px;
    cursor: pointer;
    color: black;
}

.category-name h2:hover{
    color: #3256a8;
    scale: 1.02;
}

.category-name button{
    height: 20px;
    border: none;
    background-color: transparent;
    font-size: 50px;
    color: white;
    color: black;
}

.category-name svg{
    position: absolute;
    left: 300px;
    top: 30px;
    font-size: 1.1rem;
    align-items: center;
    text-align: center;
}

.category-subcategory ul{
    list-style: none;
}

.category-subcategory ul li{
    cursor: pointer;
    color: black;
}

.category-subcategory ul li:hover{
    color: #3256a8;
    scale: 1.02;
}

.products-items{
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}

.product-card {
    height: 300px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
    box-shadow: 1px 1px 10px black;
    color: white;
    cursor: pointer;
    transition: scale 500ms;
    padding: 10px;
    overflow: hidden;
}

.product-card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1px;
    line-height: 1.2rem;
    overflow: hidden;
}

.product-card-details h2 {
    min-height: 2.4rem;
    color: black;
    font-size: 1.2rem; 
    margin: 0; 
    word-wrap: break-word;
    overflow-wrap: break-word;
    text-align: center;
}

.product-card-details p {
    color: black;
    font-size: 0.8rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-card:hover{
    scale: 1.02;
}

.product-card img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
    margin: 0;
    padding: 0;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 10px;
  }
  
  .pagination-arrow {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .pagination-arrow:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination-dot {
    background: transparent;
    border: none;
    font-size: 1.2rem;
    color: #555;
    cursor: pointer;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .pagination-dot.active {
    color: #007bff;
    transform: scale(1.2);
  }
  
  .pagination-dot:hover {
    color: #007bff;
  }

@media (max-width: 800px){
      .category-items.visible {
        display: block;
      }
    
      .category-items.hidden {
        display: none;
      }
    .category-toggle {
        display: block;
      }
    .products-main{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .brand-logo{
        position: static;
        top: auto; 
        right: auto;
        width: 200px;
    }
    .products-category{
        width: 400px;
        padding: 0px;
        margin: 0px 0px;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .category-name{
        text-align: center;
        gap: 1px;
        margin-left: 20px;
    }
    .category-name h2{
        font-size: 1.2rem;
    }
    .products-items{
        margin-top: 30px;
        flex-direction: column;
    }
    .product-card img{
        width: 300px;
    }
    .category-name button{
        font-size: 1rem;
    }
    .pagination {
        gap: 5px; /* Reduce spacing between elements */
      }
    
      .pagination-arrow {
        font-size: 1.2rem; /* Make arrows smaller */
      }
    
      .pagination-dot {
        font-size: 1rem; /* Reduce dot size */
      }
}