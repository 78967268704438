.contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
  }
  
  .contact-form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
    border: 8px solid white;
    border-radius: 20px;
    background-color: #3256a8;
    height: fit-content;
    width: 500px;
  }
  
  .contact-form label{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
  }
  
  .contact-form input{
    height: 30px;
    width: 300px;
    border: none;
    background-color: white;
    color: #3256a8;
    border-radius: 5px;
    box-shadow: 2px 2px 10px black;
  }
  
  .contact-form textarea{
      width: 100%;
      height: 200px;
      resize: both;
      overflow: auto;
      word-wrap: break-word;
      padding: 8px;
  }
  
  .contact-form button{
    width: 200px;
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    background-color: white;
    color: #3256a8;
    border: none;
    border-radius: 10px;
  }
  
  .info div{
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .contact-cards{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .contact-cards-rows{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 100px;
  }
  
  .contact-cards-rows .contact-card{
    background-color: white;
    border: 5px solid #3256a8;
    padding: 0px 10px;
    border-radius: 10px;
    width: 250px;
    line-height: 20px;
    transition: scale 500ms, background-color 500ms, color 500ms;
  }

  .contact-cards-rows .contact-card:hover{
    scale: 1.1;
    background-color: #3256a8;
    color: white;
  }
  
  .contact-cards-rows .contact-card div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .contact-cards-rows .contact-card h3{
    font-size: 1rem;
  }


  @media (max-width: 800px) {
    .contact{
      flex-direction: column;
    }
  
    .contact-form{
      width: 300px;
    }
  
    .contact-cards-rows{
      flex-direction: column;
    }
  }